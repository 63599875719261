import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { textStyle, backgroundStyle } from '../../utils'
import { MappedImage } from '../Image'
import parse from 'html-react-parser'
import { useSearchParams } from '../../libs/hooks'

export const IconModule = ({
  heading,
  headingDivider,
  subHeading,
  subModules,
  style,
  orgId,
  payerLink,
}) => {
  const findButtonLink = subModules.find(item => item.buttonLink)
  const url = useSearchParams(findButtonLink, orgId, payerLink)
  return (
    <Container
      fluid
      className="icon-module-container p-lg-5 p-md-3"
      css={backgroundStyle(style?.backgroundColor)}
    >
      <div className="mw">
        <Row className="module-heading-wrapper p-lg-4 p-md-2 m-auto text-center">
          {heading && (
            <h2
              className="module-heading p-3"
              css={textStyle(style?.headingColor)}
            >
              {heading}
            </h2>
          )}
          {headingDivider && <hr className="heading-divider" />}
          {subHeading && (
            <span
              className="module-subheading my-lg-3 my-md-2"
              css={textStyle(style?.subHeadingStyle)}
            >
              {subHeading}
            </span>
          )}
        </Row>
        <Row className="icon-module-icons p-3 text-center mx-auto">
          {subModules.map(item => {
            const IconWrapper = !item.buttonLink ? 'div' : 'a'
            return (
              <Col
                className="icon-item px-5 mx-auto"
                key={item.containerTitle}
                css={backgroundStyle(item?.style?.backgroundColor)}
              >
                <IconWrapper href={url} target={item.buttonLink && '_blank'}>
                  <MappedImage
                    className="m-auto my-3 text-center"
                    image={item.image}
                  />
                  <h3
                    className="my-3"
                    css={textStyle(item?.style?.headingColor)}
                  >
                    {item.heading}
                  </h3>
                  <p
                    className="my-3"
                    css={textStyle(item?.style?.subHeadingColor)}
                  >
                    {item.subtitle}
                  </p>
                  <p aria-hidden css={textStyle(item?.style?.textColor)}>
                    {item.bodyText && parse(item.bodyText.bodyText)}
                  </p>
                </IconWrapper>
              </Col>
            )
          })}
        </Row>
      </div>
    </Container>
  )
}

export const mapIconModuleProps = props => props
